<template>
  <div class="app-center-container">
    <h1>Oops! We can't find that...</h1>

    <v-btn color="primary" text @click="$router.back()"> back </v-btn>
  </div>
</template>

<script>
export default {
  name: 'not-found',
  title: 'Not Found',
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.app-center-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-grow: 1;
  height: 100vh;
}
</style>
